"use strict";

document.addEventListener('DOMContentLoaded', function () {
  var swiper = null; // Declare swiper variable
  var swiperContainer = document.querySelector('.swiper-container-mobile');
  if (swiperContainer) {
    // Initialize Swiper on page load
    initSwiperOnMobile();

    // Reinitialize Swiper on window resize
    window.addEventListener('resize', function () {
      initSwiperOnMobile();
    });
  }
  function initSwiperOnMobile() {
    // Check if the window is larger than 1023px (desktop)
    if (window.innerWidth > 1023) {
      // Destroy the Swiper if it exists and the window is larger than 1023px
      if (swiper) {
        swiper.destroy(true, true); // Destroy Swiper and reset its state
        swiper = null;
      }
      // Optional: remove swiper classes if you want to reset the layout to a non-swiper state on desktop
      removeSwiperClasses(swiperContainer);
    }
    // Initialize Swiper if the window is smaller than or equal to 1023px
    else if (window.innerWidth <= 1023 && !swiper) {
      // Ensure the necessary Swiper classes are in place
      addSwiperClasses(swiperContainer);

      // Initialize Swiper for mobile
      swiper = new Swiper(swiperContainer, {
        slidesPerView: 2.2,
        // Adjust as necessary for mobile
        spaceBetween: 10,
        // Adjust spacing
        breakpoints: {
          768: {
            slidesPerView: 1.5 // Adjust for narrower mobile screens
          }
        }
      });
    }
  }
  function removeSwiperClasses(container) {
    // Optional: You may want to remove custom classes to revert to non-swiper layout
    container.classList.remove('swiper-container-initialized', 'swiper-container-horizontal');
    var wrapper = container.querySelector('.swiper-wrapper');
    if (wrapper) {
      wrapper.classList.remove('swiper-wrapper');
    }

    // Remove swiper-slide class from each slide
    var slides = container.querySelectorAll('.swiper-slide');
    slides.forEach(function (slide) {
      slide.classList.remove('swiper-slide');
    });
  }
  function addSwiperClasses(container) {
    // Add the necessary Swiper container and wrapper classes for mobile
    container.classList.add('swiper-container', 'swiper-container-horizontal');
    var wrapper = container.querySelector('.grid-x'); // Assuming 'grid-x' is the wrapper class
    if (wrapper) {
      wrapper.classList.add('swiper-wrapper');
    }

    // Add swiper-slide class to each slide
    var slides = container.querySelectorAll('.cell'); // Assuming 'cell' is the slide class
    slides.forEach(function (slide) {
      slide.classList.add('swiper-slide');
    });
  }
});