"use strict";

document.addEventListener('DOMContentLoaded', function () {
  $(document).foundation();

  // Smooth Scroll for Read More
  $("#read-more").click(function () {
    $('html, body').animate({
      scrollTop: $("#category-description").offset().top
    }, 5);
  });
  var nav = document.getElementById('site-navigation');
  var menuBtn = document.querySelector('.menu-btn');
  var lines = document.querySelectorAll('.menu-btn .line');
  var header = document.querySelector('header');
  var navbarHeight = header.offsetHeight;
  var body = $("body");

  // Toggle mobile navigation
  menuBtn.addEventListener('click', function () {
    nav.classList.toggle('open-nav');
    lines.forEach(function (line) {
      return line.classList.toggle('line-cross');
    });
    lines[1].classList.toggle('line-fade-out');
  });

  // Close nav when clicking outside
  document.addEventListener('click', function (e) {
    if (!nav.contains(e.target)) {
      nav.classList.remove('nav-open');
    }
  });

  // Initialize Swiper for product thumbnails and image gallery
  var swiperProductThumbs = new Swiper(".swiper-product-thumbs", {
    spaceBetween: 10,
    slidesPerView: 5,
    freeMode: true,
    watchSlidesProgress: true
  });
  var swiperProductImage = new Swiper(".swiper-product-image", {
    spaceBetween: 10,
    autoHeight: false,
    navigation: {
      nextEl: ".swiper-product-next",
      prevEl: ".swiper-product-prev"
    },
    thumbs: {
      swiper: swiperProductThumbs
    },
    keyboard: {
      enabled: true
    },
    a11y: {
      prevSlideMessage: "Previous slide",
      nextSlideMessage: "Next slide"
    }
  });

  // Gallery Thumbs for Image Gallery
  var galleryThumbs = new Swiper(".gallery-thumbs", {
    centeredSlidesBounds: true,
    spaceBetween: 10,
    slidesPerView: 3,
    breakpoints: {
      480: {
        direction: "vertical",
        slidesPerView: 4
      }
    }
  });

  // Initialize Magnific Popup
  $('.magnific-popup').magnificPopup({
    type: 'image',
    mainClass: 'mfp-with-zoom',
    removalDelay: 300,
    gallery: {
      enabled: true
    }
  });

  // Toggle product filter dropdown
  $('.product-label-toggle').click(function () {
    $(this).closest('.product-filter').find('.product-filter-dropdown').slideToggle();
    $(this).find('i').toggleClass('open');
  });

  // Toggle mini cart
  $('.mini-cart.header').click(function () {
    $(".dropdown-menu-mini-cart").slideToggle();
    $(".account-label i").toggleClass('open');
  });

  // Update quantity input (custom quantity input)
  $('.custom-quantity-input .plus').click(function () {
    var $input = $(this).closest('.custom-quantity-input').find('.qty');
    var val = parseInt($input.val());
    if (!isNaN(val)) $input.val(val + 1).change();
  });
  $('.custom-quantity-input .minus').click(function () {
    var $input = $(this).closest('.custom-quantity-input').find('.qty');
    var val = parseInt($input.val());
    if (!isNaN(val) && val > 1) $input.val(val - 1).change();
  });

  // Variation radios change event
  $(document).on('change', '.variation-radios input', function () {
    $('.variation-radios input:checked').each(function () {
      var radio = $(this);
      var radioName = radio.attr('name');
      var radioValue = radio.attr('value');
      $("select[name=\"".concat(radioName, "\"]")).val(radioValue).trigger('change');
    });
  });
  $('.variation-radios input:checked').trigger('change');

  // Initialize top mobile swiper
  var product_swiper = null;
  var swiperProductContainer = document.querySelector('.product-swiper-slider');
  function initProductSwiperOnMobile() {
    if (window.innerWidth > 1023) {
      if (product_swiper) {
        product_swiper.destroy(true, true);
        product_swiper = null;
      }
      removeSwiperClasses(swiperProductContainer);
    } else if (!product_swiper) {
      addSwiperClasses(swiperProductContainer);
      product_swiper = new Swiper(swiperProductContainer, {
        slidesPerView: 2,
        spaceBetween: 10,
        pagination: {
          el: '.product-swiper-pagination',
          clickable: true
        },
        breakpoints: {
          1200: {
            slidesPerView: 5,
            spaceBetween: 30
          }
        }
      });
    }
  }
  if (swiperProductContainer) {
    initProductSwiperOnMobile();
    window.addEventListener('resize', initProductSwiperOnMobile);
  }

  // Check header scroll status
  function checkScroll() {
    var scrollTop = $(window).scrollTop();
    var header = $('.site-header');
    header.toggleClass('scrolled', scrollTop > 0);
  }
  checkScroll();
  $(window).on('scroll', checkScroll);

  // Sidebar filter toggle for mobile
  var aside = $('#filter-sidebar');
  if (aside.length) {
    $("#close-sidebar-filter").click(function () {
      aside.removeClass('open');
      body.removeClass('open');
    });
    $("#open-mobile-filters").click(function () {
      aside.toggleClass('open');
      body.addClass("open");
    });
    $(document).mouseup(function (e) {
      if (!aside.is(e.target) && aside.has(e.target).length === 0) {
        aside.removeClass('open');
        body.removeClass('open');
      }
    });
  }

  // Helper functions to manage Swiper classes
  function removeSwiperClasses(container) {
    var _container$querySelec;
    container.classList.remove('swiper-container-initialized', 'swiper-container-horizontal');
    (_container$querySelec = container.querySelector('.swiper-wrapper')) === null || _container$querySelec === void 0 ? void 0 : _container$querySelec.classList.remove('swiper-wrapper');
    container.querySelectorAll('.swiper-slide').forEach(function (slide) {
      return slide.classList.remove('swiper-slide');
    });
  }
  function addSwiperClasses(container) {
    container.classList.add('swiper-container', 'swiper-container-horizontal');
    var wrapper = container.querySelector('ul') || container.querySelector('div');
    if (wrapper) {
      wrapper.classList.add('swiper-wrapper');
      wrapper.querySelectorAll('li, div').forEach(function (slide) {
        return slide.classList.add('swiper-slide');
      });
    }
  }

  // Initialize top mobile swiper
  var top_swiper = null;
  var swiperContainer = document.querySelector('.swiper-top-mobile');
  function initSwiperOnMobile() {
    if (window.innerWidth > 1023) {
      if (top_swiper) {
        top_swiper.destroy(true, true);
        top_swiper = null;
      }
      removeSwiperClasses(swiperContainer);
    } else if (!top_swiper) {
      addSwiperClasses(swiperContainer);
      top_swiper = new Swiper(swiperContainer, {
        slidesPerView: 1,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        }
      });
    }
  }
  if (swiperContainer) {
    initSwiperOnMobile();
    window.addEventListener('resize', initSwiperOnMobile);
  }
});